// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-oznake-jsx": () => import("./../../../src/pages/oznake.jsx" /* webpackChunkName: "component---src-pages-oznake-jsx" */),
  "component---src-pages-pretraga-jsx": () => import("./../../../src/pages/pretraga.jsx" /* webpackChunkName: "component---src-pages-pretraga-jsx" */),
  "component---src-pages-zbirke-jsx": () => import("./../../../src/pages/zbirke.jsx" /* webpackChunkName: "component---src-pages-zbirke-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/Post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-series-jsx": () => import("./../../../src/templates/Series.jsx" /* webpackChunkName: "component---src-templates-series-jsx" */)
}

